import React from "react";
import Logo from "../imagenes/Logo.svg";
import "../estilos/Footer.css";

function Footer() {
  return (
    <body>
      <section className="section-footer">
        <footer className="top">
          <img src={require("../imagenes/Logorojo.png")} className="logofooter" alt="logo" />
          <div className="links">
            <div>
              <h2 className="titulo-footer"><b>INFORMACIÓN</b></h2>
              <a>¿Quiénes somos?</a>
              <a href="/política-de-privacidad">Política de Privacidad</a>
            </div>
            <div>
              <h2 className="titulo-footer"><b>CONTACTO</b></h2>
              <a href="tel:+34617-65-83-38">617 65 83 38</a>
              <a href="tel:+34925-37-86-05">925 37 86 05</a>
              <a href="tel:+34617-26-82-38">617 26 82 38</a>
              <a href="mailto:autoescuelacobisa@hotmail.es">autoescuelacobisa@hotmail.es</a>
            </div>
          </div>
        </footer>
        <footer className="bottom">
          <div className="legal">
            <span> © 2025 Todos los derechos reservados </span>
            {/* <a> License </a>
            <a> Términos </a>
            <a> Privacidad </a> */}
          </div>
          <div className="links">
            <a className="fa-brands fa-whatsapp" href="https://api.whatsapp.com/send?phone=617658338&text=Hola,%20vengo%20de%20la%20p%C3%A1gina%20web"></a>
            <a className="fa-brands fa-instagram" href="https://www.instagram.com/autoescuelacobisa"></a>
            <a className="fa-brands fa-facebook" href="https://es-es.facebook.com/people/Escuela-De-Conductores-Cobisa/100054437382839/"></a>
          </div>
        </footer>
      </section>
    </body>
  );
}

export default Footer;

